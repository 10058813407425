$operational: #6ab519;
$minor: $carrot;
$performance: $sunglow;
$major: $flame;
$scheduled: $java;

.status-page {
  position: relative;

  h1 {
    font-weight: 400;
  }

  dt {
    font-weight: 400;
  }

  .button {
    @include breakpoint(medium up) {
      margin-bottom: 0;
    }

    &:not(.large) {
      margin-bottom: 0;
    }
  }

  dl,
  h4,
  h5,
  h6 {
    margin-bottom: 0;
    color: $black;
  }

  hr {
    margin: rem-calc(36 0);
  }
}

.status-timestamp {
  @include breakpoint(medium up) {
    position: absolute;
    top: 0;
    right: 0;
    width: auto;
    text-align: right;
  }

  @include breakpoint(small only) {
    margin-bottom: rem-calc(16);
  }

  .time {
    font-weight: bold;
  }

  span {
    display: block;
    line-height: 1.2;
  }
}

.main-status.operational {
  display: block;
  position: relative;
  padding: rem-calc(20 16 20 64);
  border-radius: rem-calc(5);
  background: $operational;
  color: $white;
  font-size: rem-calc(20);
  font-weight: bold;
  line-height: 1;
  text-align: left;

  .icon svg {
    display: block;
    position: absolute;
    top: 50%;
    left: rem-calc(12);
    width: rem-calc(40);
    height: rem-calc(40);
    transform: translateY(-50%);
    stroke: $white;
  }
}

.incident {
  @extend %clearfix;
  position: relative;

  &.mini {
    margin-bottom: rem-calc(16);
    padding: rem-calc(10 16 16 64);
    border-width: rem-calc(3);
    border-style: solid;

    .icon svg {
      width: rem-calc(40);
      height: rem-calc(40);
    }
  }

  &.live {
    margin-bottom: rem-calc(16);
    padding: rem-calc(10 16 16 104);

    .heavy {
      margin-bottom: rem-calc(10);
      font-weight: bold;
      line-height: 1;
    }

    .icon svg {
      width: rem-calc(70);
      height: rem-calc(70);
    }
  }

  .icon svg {
    display: block;
    position: absolute;
    top: rem-calc(12);
    left: rem-calc(12);
  }

  .button {
    margin-top: rem-calc(8);
    font-weight: bold;
  }
}

.subscribe {
  display: none; // Hide subscribe links for now
  // display: block;
  position: absolute;
  top: rem-calc(6);
  right: rem-calc(8);
  padding-left: rem-calc(28);
  background-image: url(#{$img-path}subscribe.svg);
  background-repeat: no-repeat;
  background-position: center left;
  background-size: rem-calc(24 24);
  color: $grey;
  text-decoration: underline;

  &.unsubscribe {
    background-image: url(#{$img-path}unsubscribe.svg);
  }
}

.service-groups {
  @include grid-row();
}

.service-group-wrap {
  @include grid-columns(12, 6, 6, true);
}

.service-group {
  position: relative;
  margin-bottom: rem-calc(map-get($grid-column-gutter, 'small') * 2);
  padding: rem-calc(10 16);
  border-width: rem-calc(3);
  border-style: solid;

  h3 {
    margin-bottom: rem-calc(6);
  }

  ul {
    margin: 0;
    list-style: none;
  }

  li {
    margin-bottom: rem-calc(2);
    padding-left: rem-calc(28);
    background-repeat: no-repeat;
    background-position: center left;
    background-size: rem-calc(20 20);
    font-size: rem-calc(18);
    line-height: rem-calc(26);

    &::after {
      display: inline-block;
      margin-left: rem-calc(8);
      font-size: rem-calc(12);
      font-weight: bold;
      line-height: rem-calc(26);
      text-transform: uppercase;
    }

    &.operational {
      background-image: url(#{$img-path}operational.svg);

      &::after {
        color: $operational;
        content: 'Operational';
      }
    }

    &.scheduled {
      background-image: url(#{$img-path}scheduled.svg);

      &::after {
        color: $scheduled;
        content: 'Scheduled Downtime';
      }
    }

    &.minor {
      background-image: url(#{$img-path}minor.svg);

      &::after {
        color: $minor;
        content: 'Partial Outage';
      }
    }

    &.major {
      background-image: url(#{$img-path}major.svg);

      &::after {
        color: $major;
        content: 'Major Outage';
      }
    }

    &.performance {
      background-image: url(#{$img-path}performance.svg);

      &::after {
        color: $performance;
        content: 'Performance Issues';
      }
    }
  }
}

.timeline {
  position: relative;
  left: rem-calc(-20);
  width: calc(1.25rem + 100%);
  padding-left: rem-calc(17);
  border-left: rem-calc(3) solid $grey;

  dt {
    display: block;
    position: absolute;
    left: rem-calc(-82);
    width: rem-calc(64);
    margin-top: rem-calc(5);
    color: $black;
    font-size: rem-calc(12);
    text-align: right;

    &::after {
      display: block;
      position: absolute;
      top: 0;
      right: rem-calc(-24);
      width: rem-calc(15);
      height: rem-calc(15);
      border-radius: 50%;
      background: $grey;
      content: '';
      z-index: 2;
    }

    [data-tooltip] {
      border: 0;
      line-height: 1;

      &::after {
        font-size: rem-calc(13);
      }
    }
  }

  dd {
    position: relative;
  }

  dd:first-of-type::before {
    display: block;
    position: absolute;
    top: rem-calc(-15);
    left: rem-calc(-26);
    width: rem-calc(15);
    height: 30px;
    background: $white;
    content: '';
  }

  dd:last-of-type::before {
    display: block;
    position: absolute;
    top: auto;
    bottom: rem-calc(-15);
    left: rem-calc(-26);
    width: rem-calc(15);
    height: 100%;
    background: $white;
    content: '';
  }

  dd:first-of-type:last-of-type::before {
    top: rem-calc(-15);
    bottom: auto;
    height: calc(100% + 1rem);
  }
}

.incident,
.service-group,
.service-group li {
  &.scheduled {
    border-color: $scheduled;

    .icon svg {
      stroke: $scheduled;
    }

    .button {
      background-color: $scheduled;
    }

    .heavy {
      color: $scheduled;
    }

    .timeline {
      border-left-color: $scheduled;
    }

    .timeline dt::after {
      background-color: $scheduled;
    }
  }

  &.operational {
    border-color: $operational;

    .icon svg {
      stroke: $operational;
    }

    .button {
      background-color: $operational;
    }

    .heavy {
      color: $operational;
    }

    .timeline {
      border-left-color: $operational;
    }

    .timeline dt::after {
      background-color: $operational;
    }
  }

  &.minor {
    border-color: $minor;

    .icon svg {
      stroke: $minor;
    }

    .button {
      background-color: $minor;
    }

    .heavy {
      color: $minor;
    }

    .timeline {
      border-left-color: $minor;
    }

    .timeline dt::after {
      background-color: $minor;
    }
  }

  &.performance {
    border-color: $performance;

    .icon svg {
      stroke: $performance;
    }

    .button {
      background-color: $performance;
    }

    .heavy {
      color: $performance;
    }

    .timeline {
      border-left-color: $performance;
    }

    .timeline dt::after {
      background-color: $performance;
    }
  }

  &.major {
    border-color: $major;

    .icon svg {
      stroke: $major;
    }

    .button {
      background-color: $major;
    }

    .heavy {
      color: $major;
    }

    .timeline {
      border-left-color: $major;
    }

    .timeline dt::after {
      background-color: $major;
    }
  }
}

.ditto {
  margin-bottom: rem-calc(32);

  p {
    margin-bottom: rem-calc(8);
  }

  .button {
    padding-left: rem-calc(40);
    background-image: url(#{$img-path}plus-one.svg);
    background-repeat: no-repeat;
    background-position: center left rem-calc(8);
    background-size: rem-calc(24 24);

    &.doditto {
      background-image: url(#{$img-path}plus-one.svg);
    }

    &.unditto {
      @include button-style($medium-gray, scale-color($medium-gray, $lightness: -15%), $white);
      background-image: url(#{$img-path}minus-one.svg);
    }
  }
}

.button.refresh {
  margin-top: rem-calc(-2);
  margin-right: rem-calc(8);
  padding: rem-calc(5 8);
  font-size: rem-calc(12);

  &.is-refresh {
    position: relative;
    padding-left: rem-calc(25);

    &::before {
      display: block;
      position: absolute;
      // top: rem-calc(6);
      // left: rem-calc(5);
      // width: rem-calc(9);
      // height: rem-calc(9);
      border-radius: 50%;
      background: $white;
      box-shadow: 0 0 0 scale-color($white, $alpha: -60%);
      content: '';
      animation: pulse 2s infinite;
    }
  }
}

@keyframes pulse {
  0% {
    top: rem-calc(7);
    left: rem-calc(9);
    width: rem-calc(7);
    height: rem-calc(7);
    box-shadow: 0 0 0 0 scale-color($white, $alpha: -60%);
  }

  30% {
    top: rem-calc(6);
    left: rem-calc(8);
    width: rem-calc(9);
    height: rem-calc(9);
  }

  70% {
    box-shadow: 0 0 0 rem-calc(12) scale-color($white, $alpha: -100%);
  }

  100% {
    top: rem-calc(7);
    left: rem-calc(9);
    width: rem-calc(7);
    height: rem-calc(7);
    box-shadow: 0 0 0 0 scale-color($white, $alpha: -100%);
  }
}
