@charset 'utf-8';

// --------------------------
// Import APC brand framework
// --------------------------
@import 'apc';

// ------------------
// Overwrite settings
// ------------------

$img-path: '../img/';

// ------------------
// Include everything
// ------------------

$breakpoints: (
  small: 0,
  medium: 700px,
  large: 1100px,
  xlarge: 1200px,
  xxlarge: 1800px,
);

$black: #303131;

@include apc-everything

@import 'components/status-page';
